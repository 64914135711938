import React, { FC } from 'react'
import useRefHeight from '../../hooks/use-ref-height'
import { Box } from '@mui/material'
import { css } from '@emotion/css'
import { NftPageQuery } from 'gatsby-graphql'
import { SectionHeader } from '../../components/section-header'
import { heroSectionTransformer } from '../../transforms/hero-section-transformer'

type Props = {
  contents: NftPageQuery['strapiNft']['Nft']
}

export const MobileHeroSection: FC<Props> = ({ contents }) => {
  const { ref, height } = useRefHeight()
  const { title, description, video } = heroSectionTransformer(contents)

  return (
    <section
      className={css`
        height: ${height + 300};
      `}
    >
      <Box
        sx={{
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000503',
          overflow: 'hidden',
        }}
      >
        <iframe
          className={css`
            width: 100%;
            height: 100%;
          `}
          src={video}
          style={{ border: 'none' }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Video"
        ></iframe>
      </Box>
      <Box
        sx={{
          height: height + 40,
          background: (theme) => theme.palette.background.baseLight,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
        }}
      />
      <Box sx={{ borderRadius: 4, position: 'absolute', top: '320px', mx: 2, p: 2.25, background: 'white' }}>
        <SectionHeader ref={ref} title={title} description={description} />
      </Box>
    </section>
  )
}
