import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material'
import React, { forwardRef } from 'react'
import { v2theme } from 'src/theme/ThemeProvider'
import NftV2RichText from './nftv2-rich-text'

type Props = {
  title: string
  description?: string
  sx?: SxProps<Theme>
  textAlign?: 'left' | 'center' | 'right'
}

export const SectionHeader = forwardRef<HTMLDivElement, Props>(
  ({ title, description, sx, textAlign = 'left' }, ref) => {
    const isLgUp = useMediaQuery(v2theme.breakpoints.up('lg'))

    return (
      <Box sx={sx} ref={ref}>
        <Typography
          gutterBottom
          sx={{ fontSize: { xs: '28px', sm: '30px', md: '30px', lg: '48px' }, textWrap: 'balance' }}
          fontWeight={700}
          component="h1"
          color="text.primary"
          textAlign={textAlign}
          lineHeight={isLgUp ? '50px' : '36px'}
        >
          {title}
        </Typography>
        {!!description && (
          <NftV2RichText
            markdown={description}
            sx={{
              mt: 0,

              textAlign: textAlign,
              fontSize: { xs: '16x', sm: '16px', md: '16px', lg: '18px' },
              fontWeight: 400,
              textWrap: 'balance',
              sx: {
                '& p': {
                  color: '#475069',
                },
              },
            }}
          />
        )}
      </Box>
    )
  },
)
