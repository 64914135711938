import { NftPageQuery, Strapi__Media } from 'gatsby-graphql'
import React, { FC } from 'react'
import { perkSectionTransformer } from '../../transforms/perk-section-transformer'
import { SectionHeader } from '../../components/section-header'
import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import { v2theme } from 'src/theme/ThemeProvider'
import Image from '@/components/image'
import { Perks } from './perk/perk'
import { css } from '@emotion/css'

type Props = {
  contents: NftPageQuery['strapiNft']['NftPerk']
  onClick: () => void
}

export const DesktopPerkSection: FC<Props> = ({ contents, onClick }) => {
  const { title, description, adsTitle, adsDescription, video, desktopBackgroundImage, informationButton, perks } =
    perkSectionTransformer(contents)
  const isSmToMd = useMediaQuery(v2theme.breakpoints.between('sm', 'md'))
  const isLgUp = useMediaQuery(v2theme.breakpoints.up('lg'))

  return (
    <Box
      className={css`
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 0 100vmax white;
        clip-path: inset(0 -100vmax);
      `}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={isLgUp ? 10 : 5}>
        <Box maxWidth="600px">
          <SectionHeader textAlign="center" title={title} description={description} />
        </Box>

        <Box position={'relative'} height={500} width={500}>
          <Box
            position={'absolute'}
            top={0}
            left={0}
            sx={{
              transform: isSmToMd ? 'translate(20%, 10%)' : 'translate(0%, -1%)',
            }}
            width={isSmToMd ? '360px' : '500px'}
          >
            <Image media={desktopBackgroundImage as Strapi__Media} style={{ objectFit: 'cover' }} />
          </Box>

          <Box
            width={isSmToMd ? 140 : 175}
            position={'absolute'}
            left="0px"
            top="0px"
            sx={{
              backgroundColor: 'white',
              transform: isSmToMd ? 'translate(-8%, 80%)' : 'translate(-40%, 90%)',
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: isSmToMd ? '24px' : '28px',
                fontWeight: 700,
                lineHeight: '30px',
              }}
            >
              {adsTitle}
            </Typography>
            <Typography gutterBottom fontWeight={'700'} color={'#0C111F'}>
              {adsDescription}
            </Typography>
            <Button onClick={onClick} variant="outlined" color="primary">
              {informationButton.text}
            </Button>
          </Box>
          <Box
            width={isSmToMd ? 140 : 175}
            position={'absolute'}
            right="0px"
            top="0px"
            sx={{
              transform: isSmToMd ? 'translate(55%, 22%)' : 'translate(110%, 13%)',
            }}
          >
            <Box height={isSmToMd ? 'auto' : 450} display="flex" flexDirection="column" justifyContent="space-between">
              <Perks perks={perks} />
            </Box>
          </Box>
          <Box
            width={isSmToMd ? 164 : 240}
            height={isSmToMd ? 164 : 240}
            position={'absolute'}
            left="50%"
            top="50%"
            sx={{
              transform: isSmToMd ? 'translate(-50%, -55%)' : 'translate(-50%, -42%)',
            }}
          >
            <iframe
              className={css`
                width: 100%;
                height: 100%;
              `}
              src={video}
              style={{ border: 'none' }}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Video"
            ></iframe>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
