import { Box } from '@mui/material'
import { NftPageQuery } from 'gatsby-graphql'
import { IconButton } from 'gatsby-theme-material-ui'
import React, { FC } from 'react'
import { ImportantAlert } from './important-alert'
import { CashbackInfo } from './cashback-info'
import { AfterPurchasingStep } from './after-purchasing-step'
import CloseIcon from '@mui/icons-material/Close'
import { FaqAccordion } from './cashback-faq'

type Props = {
  contents: NftPageQuery['strapiNft']['NftMoreInformation']
  cashbackContent: NftPageQuery['strapiNft']
  onClose: () => void
}
export const MoreInformationDrawer: FC<Props> = ({ contents, cashbackContent, onClose }) => (
  <>
    <IconButton onClick={onClose} sx={{ position: 'fixed', right: '18px', top: '12px', background: 'white' }}>
      <CloseIcon />
    </IconButton>
    <Box pt={7} pb={{ xs: 2, sm: 2, md: 4 }}>
      <ImportantAlert
        title={contents.topImportantAlert}
        description={contents.topImportantAlertDescription.data.topImportantAlertDescription}
      />
    </Box>

    <Box py={{ xs: 2, sm: 2, md: 4 }}>
      <CashbackInfo
        title={contents.cashbackTitle}
        description={contents.cashbackDescription}
        shortDescription={contents.cashbackMoreInformation}
        table={contents.NftPriceAndCashbackTable}
        tableHeader={contents.NftCashbackTableHeader}
      />
    </Box>
    <Box sx={{ background: '#F7FAFD' }} py={{ xs: 2, sm: 2, md: 4 }}>
      <AfterPurchasingStep
        title={contents.AfterPurchasingTitle}
        step1={contents.AfterPurchasingSteps1}
        step2={contents.AfterPurchasingSteps2}
        step3={contents.AfterPurchasingSteps3}
        step4={contents.AfterPurchasingSteps4}
        termsAndCondition={contents}
        links={contents.links}
      />
    </Box>
    <Box sx={{ background: '#E9F1F9' }} pt={{ xs: 2, sm: 2, md: 4 }} pb={10}>
      <FaqAccordion
        title={cashbackContent.cashbackFAQTitle}
        description={cashbackContent.cashbackFAQDescription}
        contents={cashbackContent.CashbackFAQ}
      />
      {contents?.bottomImportantAlert && (
        <Box pt={{ xs: 2, sm: 4 }}>
          <ImportantAlert
            title={contents.bottomImportantAlert}
            description={contents.bottomImportantAlertDescription}
          />
        </Box>
      )}
    </Box>
  </>
)
