import { Box, StepLabel, Typography } from '@mui/material'
import { NftPageQuery } from 'gatsby-graphql'
import React, { FC } from 'react'

type Props = NftPageQuery['strapiNft']['NftFooternotes']['steps'][0] & {
  currentStep: number
}

export const Step: FC<Props> = (step) => {
  return (
    <Box mx={1}>
      <StepLabel
        sx={{ display: 'flex', alignItems: 'start', width: '100%' }}
        StepIconComponent={() => (
          <Box
            border="1px solid #CBD1E1"
            borderRadius={1.5}
            sx={{
              background: 'white',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              width: 28,
              height: 28,
            }}
          >
            <Typography variant="h6" fontSize="14px" fontWeight="bold" sx={{ opacity: 0.62 }}>
              {step.currentStep === 1 ? '*' : step.currentStep === 2 ? '**' : '***'}
            </Typography>
          </Box>
        )}
      >
        <Typography fontSize={14} color="#475069" maxWidth={{ xs: '250px', sx: '200px' }}>
          {step.Content.data.Content}
        </Typography>
      </StepLabel>
    </Box>
  )
}
