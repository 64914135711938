import { Box, FormControlLabel, Checkbox } from '@mui/material'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { Button } from 'gatsby-theme-material-ui'
import React, { FC } from 'react'
import ChevronRightIcon from '../mui-icon/chevron-right'
import ConnectIcon from '../mui-icon/connect'
import RichText from '../rich-text'

type Props = {
  connectWalletToc: string
}

export const ConnectWalletButton: FC<Props> = ({ connectWalletToc }) => {
  const { openConnectModal } = useConnectModal()
  const [checked, setChecked] = React.useState(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  return (
    <Box sx={{ width: { xs: '100%' }, margin: 'auto' }}>
      <Box py={1}>
        <Button
          color="success"
          variant="contained"
          disabled={!checked}
          onClick={() => openConnectModal?.()}
          endIcon={<ChevronRightIcon sx={{ width: '12px', height: '12px' }} />}
          startIcon={<ConnectIcon sx={{ width: '16px', fontSize: '16px' }} />}
        >
          Connect Your Wallet
        </Button>
      </Box>
      <Box>
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              color="primary"
              sx={{
                pl: '-10px',
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },

                '&.MuiCheckbox-root': {
                  color: 'white',
                },
              }}
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          color="white"
          label={
            <Box maxWidth={300}>
              <RichText sx={{ color: 'white' }} hyperlinkColor="#419864" markdown={connectWalletToc} />
            </Box>
          }
          labelPlacement="end"
        />
      </Box>
    </Box>
  )
}
