import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'
import { NftPageQuery } from 'gatsby-graphql'
import { Step } from './step'

type Props = {
  contents: NftPageQuery['strapiNft']['NftFooternotes']
}

export const FooterNoteView: FC<Props> = ({ contents }) => {
  return (
    <Box
      py="22px"
      sx={{
        background: (theme) => theme.palette.background.baseLight,
        boxShadow: (theme) => `0 0 0 100vmax ${theme.palette.background.baseLight}`,
        clipPath: 'inset(0 -100vmax)',
      }}
    >
      <Typography sx={{ mb: 3 }} variant="h4" fontSize="20px" align="center" color="text.primary" fontWeight="bold">
        {contents.title}
      </Typography>

      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        justifyContent={{ xs: 'start', sm: 'center', md: 'center', lg: 'center' }}
        flexWrap="wrap"
      >
        {contents.steps.map((step, index) => {
          return <Step key={step.Content.data.id} currentStep={index + 1} Content={step.Content} />
        })}
      </Box>
    </Box>
  )
}
