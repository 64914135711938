import { Drawer, useMediaQuery } from '@mui/material'
import { NftPageQuery } from 'gatsby-graphql'
import React, { useState, FC } from 'react'
import { v2theme } from 'src/theme/ThemeProvider'
import { MobilePerkSection, DesktopPerkSection } from '.'
import { MoreInformationDrawer } from './more-information-drawer'

type Props = {
  contents: NftPageQuery['strapiNft']['NftPerk']
  drawerContents: NftPageQuery['strapiNft']['NftMoreInformation']
  cashbackContent: NftPageQuery['strapiNft']
}

export const PerksSection: FC<Props> = ({ contents, drawerContents, cashbackContent }) => {
  const isSmDown = useMediaQuery(v2theme.breakpoints.down('sm'))
  const isSmUp = useMediaQuery(v2theme.breakpoints.up('sm'))
  const [state, setState] = useState(false)
  const toggleDrawer = (newOpen: boolean) => {
    setState(newOpen)
  }

  return (
    <section id="perk-section">
      {isSmDown && <MobilePerkSection onClick={() => toggleDrawer(true)} contents={contents} />}
      {isSmUp && <DesktopPerkSection onClick={() => toggleDrawer(true)} contents={contents} />}
      <Drawer
        PaperProps={{
          sx: {
            width: {
              xs: '100%',
              md: '768px',
              lg: '1000px',
            },
          },
        }}
        anchor="right"
        open={state}
        onClose={() => toggleDrawer(false)}
      >
        <MoreInformationDrawer
          onClose={() => toggleDrawer(false)}
          contents={drawerContents}
          cashbackContent={cashbackContent}
        />
      </Drawer>
    </section>
  )
}
