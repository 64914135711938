import React, { FC } from 'react'
import { NftPageQuery, Strapi__Media } from 'gatsby-graphql'
import Image from '@/components/image'
import { Box, Typography } from '@mui/material'

type Props = NftPageQuery['strapiNft']['NftFeaturePerk']['cards'][0]

export const Card: FC<Props> = (card) => {
  return (
    <Box px={3} py={3} sx={{ background: '#E9F1F9', borderRadius: 4, height: '100%' }}>
      <Box
        height="48px"
        width="48px"
        sx={{
          borderRadius: '50%',
          background: (theme) => theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textWrap: 'balance',
        }}
      >
        <Image media={card.icon as Strapi__Media} style={{ padding: 'auto', width: '20px', height: '20px' }} />
      </Box>
      <Box>
        <Typography gutterBottom variant="h6" fontWeight={600} color="text.primary" mt={4} fontSize={19}>
          {card.title}
        </Typography>
        <Typography variant="body1" color="#475069" mt={1.5}>
          {card.description}
        </Typography>
      </Box>
    </Box>
  )
}
