import React, { FC, useState } from 'react'
import { Box, Table, TableHead, TableCell, TableContainer, TableRow, useMediaQuery } from '@mui/material'
import Background from 'src/assets/nftv2-bg-pattern.svg'

import { theme, v2theme } from 'src/theme/ThemeProvider'
import RichText from '@/components/elements/rich-text'
import { css } from '@emotion/css'
import { Icon } from '@/components/elements/icon'
import { SectionHeader } from '@/components/sections/nft-v2/components/section-header'
import { NftPageQuery } from 'gatsby-graphql'
import { InformationDialog } from './information-dialog'

type Props = {
  contentHeader: NftPageQuery['strapiNft']['NftTableInformation']
  contents: NftPageQuery['strapiNft']['NftTable']
}

export const TableSection: FC<Props> = ({ contentHeader, contents }) => {
  const [modalStatus, setModalStatus] = useState(false)
  const [modalContent, setModalContent] = useState({ title: '', content: '' })
  const islgDown = useMediaQuery(v2theme.breakpoints.down('lg'))
  const isSmDown = useMediaQuery(v2theme.breakpoints.down('sm'))
  return (
    <Box py={islgDown ? 5 : 10} position="relative">
      <Box width="110%" position="absolute" bottom="-100px" zIndex={-1} sx={{ transform: 'translate(-5%, -50%)' }}>
        <Background width="100%" />
      </Box>
      <Box display="flex" justifyContent="center">
        <Box maxWidth="600px" mb={4}>
          <SectionHeader title={contentHeader.title} description={contentHeader.description} textAlign="center" />
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" height="100%" position="relative">
        <TableContainer
          sx={{
            boxShadow: '0px 6px 36px -15.159px rgba(153, 171, 206, 0.43)',
            borderRadius: 2,
            fontSize: { sm: '20px', xs: '16px' },
            width: isSmDown ? '100%' : '80%',

            background: 'white',
          }}
        >
          <Table
            sx={{
              borderCollapse: 'collapse',
              borderStyle: 'hidden',
            }}
          >
            <TableHead sx={{ height: '56px' }}>
              <TableRow sx={{ background: (theme) => theme.palette.primary.main }}>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: { sm: '20px', xs: '16px' },
                    fontWeight: 700,
                    color: 'white',
                  }}
                >
                  {contents.NftTableRowFirstColumn.Title}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: { sm: '20px', xs: '16px' },
                    fontWeight: 700,
                    color: 'white',
                  }}
                >
                  {contents.NftTableRowFirstColumn.NftHoldersTextValue}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: { sm: '20px', xs: '16px' },
                    fontWeight: 700,

                    color: 'white',
                  }}
                >
                  {contents.NftTableRowFirstColumn.UsdSubscribersTextValue}
                </TableCell>
              </TableRow>
            </TableHead>
            {contents.NftTableRow.map((row, index) => (
              <TableRow
                key={index}
                sx={row.RowBackground === 'dimmed' ? { background: '#F1F5F9', height: '50px' } : { height: '50px' }}
              >
                <TableCell
                  sx={{
                    borderBottom: `1px solid ${row.RowBackground === 'dimmed' ? '#e0e0e0' : theme.palette.grey[300]}`,
                    padding: { sm: '12px', xs: '8px' },
                    paddingLeft: '17px',
                    fontSize: { sm: '20px', xs: '16px' },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {row?.PopupData != null && row?.PopupData?.ShowPopUp && (
                      <Box
                        sx={{
                          minWidth: '20px',
                          height: '20px',
                          backgroundColor: `${theme.palette.success.main}`,
                          borderRadius: '25px',
                          objectFit: 'contained',
                          cursor: 'pointer',
                          marginRight: '15px',
                          display: 'inline-block',

                          '&:hover': {
                            backgroundColor: (theme) => `${theme.palette.primary.main}`,
                            transition: `all 0.3s ease-in-out`,
                          },
                        }}
                        onClick={() => {
                          setModalContent({
                            title: row?.PopupData.Heading,
                            content: row?.PopupData.Content?.data?.Content,
                          })
                          setModalStatus(true)
                        }}
                      >
                        <img
                          className=""
                          src="/images/nftv2-question-circle.svg"
                          alt="vertical line"
                          width={20}
                          height={20}
                          style={{ display: 'block' }}
                        />
                      </Box>
                    )}
                    <RichText
                      className={css`
                        & p {
                          margin-block-start: 0;
                          margin-block-end: 0;
                          line-height: 186%;
                        }
                        text-align: ${row.TextAlignment === 'center' ? 'center' : 'left'};
                      `}
                      hyperlinkColor="#1F9870"
                      markdown={row.RowTitle.data.RowTitle}
                      preserveNewlines
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: `1px solid ${row.RowBackground === 'dimmed' ? 'white' : theme.palette.background.light}`,
                    borderBottom: `1px solid ${row.RowBackground === 'dimmed' ? '#e0e0e0' : theme.palette.grey[300]}`,
                    padding: { sm: '12px', xs: '8px' },
                    textAlign: 'center',
                    fontSize: { sm: '20px', xs: '16px' },
                  }}
                >
                  {row.NftHoldersColumnTextValue}
                  {row.NftHoldersColumnIconValue &&
                    row.NftHoldersColumnIconValue !== 'none' &&
                    (row.NftHoldersColumnIconValue === 'checkmark' ? (
                      <Icon icon="checkmark" />
                    ) : (
                      <Icon icon="redcross" />
                    ))}
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: `1px solid ${row.RowBackground === 'dimmed' ? 'white' : theme.palette.background.light}`,
                    borderBottom: `1px solid ${row.RowBackground === 'dimmed' ? '#e0e0e0' : theme.palette.grey[300]}`,
                    padding: { sm: '12px', xs: '8px' },
                    textAlign: 'center',
                    fontSize: { sm: '20px', xs: '16px' },
                  }}
                >
                  {row.UsdSubscribersColumnTextValue}
                  {row.UsdSubscribersColumnIconValue &&
                    row.UsdSubscribersColumnIconValue !== 'none' &&
                    (row.UsdSubscribersColumnIconValue === 'checkmark' ? (
                      <Icon icon="checkmark" />
                    ) : (
                      <Icon icon="redcross" />
                    ))}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Box>

      <InformationDialog
        open={modalStatus}
        onOpen={() => {
          setModalStatus(true)
        }}
        onClose={() => {
          setModalStatus(false)
        }}
        title={modalContent.title}
        description={modalContent.content}
      />
    </Box>
  )
}
