import { NftPageQuery } from 'gatsby-graphql'
import React, { FC } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { css } from '@emotion/css'
import { v2theme } from 'src/theme/ThemeProvider'
import { heroSectionTransformer } from '../../transforms/hero-section-transformer'
import { SectionHeader } from '../../components/section-header'

type Props = {
  contents: NftPageQuery['strapiNft']['Nft']
}

export const DesktopHeroSection: FC<Props> = ({ contents }) => {
  const { title, description, video } = heroSectionTransformer(contents)
  const isSmToLg = useMediaQuery(v2theme.breakpoints.between('sm', 'lg'))
  const isLgUp = useMediaQuery(v2theme.breakpoints.up('lg'))

  return (
    <Box
      className={css`
        height: ${isSmToLg ? '550px' : '680px'};
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 0 100vmax white;
        clip-path: inset(0 -100vmax);
      `}
    >
      <Box
        width="50%"
        height="100%"
        position="relative"
        display="flex"
        alignItems="center"
        sx={{
          background: (theme) => theme.palette.background.baseLight,
          boxShadow: (theme) => `-100vmax 0 0 100vmax ${theme.palette.background.baseLight}`,
          clipPath: 'inset(0 -100vmax)',
        }}
      >
        <Box
          sx={{
            maxWidth: '600px',
            ml: isLgUp ? 0 : 6,
            p: isLgUp ? 6 : 2.25,
            borderRadius: 4,
            background: (theme) => theme.palette.background.paper,
            position: 'absolute',
            right: '-10%',
            zIndex: 2, // Ensure this is above the iframe
          }}
        >
          <SectionHeader title={title} description={description} />
        </Box>
      </Box>

      <Box
        width="50%"
        height="100%"
        position="relative"
        sx={{
          zIndex: -1,
          backgroundColor: '#000503',
          boxShadow: `100vmax 0 0 100vmax #000503`,
          clipPath: 'inset(0 -100max)',
        }}
      >
        <iframe
          className={css`
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            border: none;
            @media (min-aspect-ratio: 16/9) {
              width: 177.78vh;
            }

            @media (max-aspect-ratio: 16/9) {
              width: 177.78vh;
            }
          `}
          src={video}
          style={{ border: 'none' }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Video"
        ></iframe>
      </Box>
    </Box>
  )
}
