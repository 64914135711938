import { NftPageQuery } from 'gatsby-graphql'

type HeroSection = {
  title: string
  description: string
  video: string
}

export const heroSectionTransformer = (data: NftPageQuery['strapiNft']['Nft']): HeroSection => {
  return {
    title: data.Title,
    description: data.FirstParagraph.data.FirstParagraph,
    video: data.VimeoUrl,
  }
}
