import { Box, Container, Paper, Typography, useMediaQuery } from '@mui/material'
import { NftPageQuery, Strapi__Media } from 'gatsby-graphql'

import React, { FC } from 'react'
import CountUp from 'react-countup'
import { v2theme } from 'src/theme/ThemeProvider'
import { modifyPriceRange } from '../../utils/modify-current-price-range'
import Image from '@/components/image'

type Props = {
  content: NftPageQuery['strapiNft']['NftMintV2']
  contact: { supply: bigint; totalSupply: bigint; price: bigint }
  children: React.ReactNode
}

type NftPriceCardProps = {
  type: 'past' | 'current' | 'upcoming'
  hidePrice: boolean
  title: string
  description: string
}

const NftPriceCard: FC<NftPriceCardProps> = ({ type, hidePrice, title, description }) => {
  const isCurrentPrice = type === 'current'
  const cardType = {
    past: {
      backgroundColor: '#F2504F',
      titleColor: '#FFDBDB',
      descriptionColor: '#370707',
      descriptionFontWeight: 600,
      isCurrent: isCurrentPrice,
    },
    current: {
      backgroundColor: '#419864',
      titleColor: '#FFFFFF',
      descriptionColor: '#FFCB3D',
      descriptionFontWeight: 800,
      isCurrent: isCurrentPrice,
    },
    upcoming: {
      backgroundColor: '#00277A',
      titleColor: '#FFFFFF',
      descriptionColor: '#27BE8C',
      descriptionFontWeight: 600,
      isCurrent: isCurrentPrice,
    },
  }

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          borderRadius: '10px',
          height: '150px',
          width: '170px',
          backgroundColor: cardType[type].backgroundColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {cardType[type].isCurrent && (
          <Box
            position="absolute"
            left={0}
            top={0}
            px={2}
            sx={{ borderRadius: '10px 20px 20px 0px', backgroundColor: '#FFD600' }}
          >
            <Typography fontSize="16px" fontWeight={700} color="#02289A">
              Current Price
            </Typography>
          </Box>
        )}
        <Box>
          <Typography mb={-1} component="h2" fontSize="20px" color={cardType[type].titleColor} fontWeight={700}>
            {title}
          </Typography>

          <Typography
            fontWeight={cardType[type].descriptionFontWeight}
            fontSize="18px"
            color={cardType[type].descriptionColor}
          >
            {!hidePrice ? description : 'MINTED'}
          </Typography>
        </Box>
      </Paper>
    </>
  )
}

export const MintSection: FC<Props> = ({ content, contact, children }) => {
  const remainingSupply = Number(contact.totalSupply) - Number(contact.supply)
  const xlDown = useMediaQuery(v2theme.breakpoints.down('xl'))
  const nftRanges = modifyPriceRange(content.nftPriceAndRange, {
    price: contact.price,
    supply: Number(contact.supply),
  })

  return (
    <>
      {xlDown ? (
        <>
          <Box sx={{ background: '#0042CD', pt: 6, pb: 2 }}>
            <Container maxWidth="sm" disableGutters>
              <Box sx={{ lineHeight: '125%', px: '20px' }}>
                <Typography fontSize="36px" color="white" width="80%" fontWeight="bold">
                  {content.title}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" sx={{ px: '20px', py: '20px' }}>
                <Box
                  sx={{
                    background: 'white',
                    display: 'inline-block',
                    position: 'relative',
                  }}
                >
                  <Box
                    position="absolute"
                    height="58px"
                    right="-1px"
                    top="0px"
                    sx={{ background: 'white', transform: 'translate(0%, -50%)' }}
                    width={'100vw'}
                  />
                </Box>
                <Box
                  sx={{
                    background: 'white',
                    display: 'inline-block',
                    padding: '2px 16px 2px 0px',
                    borderTopRightRadius: '36px',
                    borderBottomRightRadius: '36px',
                  }}
                >
                  <Typography fontSize="36px" fontWeight={400} color={(theme) => theme.palette.primary.main}>
                    Only{' '}
                    <Typography
                      fontSize="36px"
                      component="span"
                      fontWeight={700}
                      color={(theme) => theme.palette.primary.main}
                    >
                      <CountUp
                        start={Number(contact.totalSupply)}
                        end={Number(remainingSupply)}
                        useGrouping={false}
                        duration={2.5}
                      />
                    </Typography>{' '}
                    Remain
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ lineHeight: '125%', px: '20px', pb: 2 }}>{children}</Box>
            </Container>
          </Box>
          <Box
            sx={{
              background: '#102E6F',
              py: 5,
              backgroundImage: `url(${content.backgroundImage.localFile.publicURL})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Container maxWidth="sm" disableGutters>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 2,
                  justifyContent: 'center',
                }}
              >
                {nftRanges.map((nftRange, index) => {
                  return (
                    <Box key={index} display="flex" justifyContent="center">
                      <NftPriceCard
                        title={nftRange.range}
                        description={nftRange.value}
                        type={nftRange.type}
                        hidePrice={nftRange.hidePrice}
                      />
                    </Box>
                  )
                })}
                <Box
                  sx={{
                    height: '150px',
                    width: '356px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '10px',
                    position: 'relative',
                    p: 3,
                    background: 'linear-gradient(290deg, #0042CD 7.81%, #00277A 99.91%)',
                  }}
                >
                  <Box width={146}>
                    <Typography color="white" fontSize="18px" fontWeight={600}>
                      {content.tile.title}
                    </Typography>
                  </Box>
                  <Box position={'absolute'} width="100px" right="24px" bottom={0}>
                    <Image media={content.tile.image as Strapi__Media} style={{ objectFit: 'contain' }} />
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        <Box position={'relative'} height={820} my={6}>
          <Box
            sx={{ background: '#0042CD', width: '50%' }}
            height={600}
            display="flex"
            alignItems="center"
            justifyContent="end"
            left="-40px"
            position="absolute"
            zIndex={1}
          >
            <Box position="absolute" left={-20} zIndex={1} px={10} width={800}>
              <Box sx={{ lineHeight: '125%', px: '20px', width: '100%' }}>
                <Typography fontSize={'46px'} color="white" width="60%" fontWeight="bold">
                  {content.title}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ px: '20px', py: '20px' }}>
                <Box
                  sx={{
                    background: 'white',
                    display: 'inline-block',
                    position: 'relative',
                  }}
                >
                  <Box
                    position="absolute"
                    height="58px"
                    right="-1px"
                    top="0px"
                    sx={{ background: 'white', transform: 'translate(0%, -50%)' }}
                    width={'100vw'}
                  />
                </Box>
                <Box
                  sx={{
                    background: 'white',
                    display: 'inline-block',
                    padding: '2px 16px 2px 0px',
                    borderTopRightRadius: '56px',
                    borderBottomRightRadius: '56px',
                  }}
                >
                  <Typography fontSize="36px" fontWeight={400} color={(theme) => theme.palette.primary.main}>
                    Only{' '}
                    <Typography
                      fontSize="36px"
                      component="span"
                      fontWeight={700}
                      color={(theme) => theme.palette.primary.main}
                    >
                      <CountUp
                        start={Number(contact.totalSupply)}
                        end={Number(remainingSupply)}
                        useGrouping={false}
                        duration={2.5}
                      />
                    </Typography>{' '}
                    Remain
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ lineHeight: '125%', px: '20px', pb: 2 }}>{children}</Box>
            </Box>
          </Box>
          <Box
            position={'absolute'}
            zIndex={0}
            left={'calc(50% - 100px)'}
            width={'60%'}
            top={'58px'}
            sx={{
              background: '#102E6F',
              py: 10,
              backgroundImage: `url(${content.backgroundImage.localFile.publicURL})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                sx={{
                  width: '600px',
                  height: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 2,
                  justifyContent: 'center',
                }}
              >
                {nftRanges.map((nftRange, index) => {
                  return (
                    <Box key={index} display="flex" justifyContent="center">
                      <NftPriceCard
                        title={nftRange.range}
                        description={nftRange.value}
                        type={nftRange.type}
                        hidePrice={nftRange.hidePrice}
                      />
                    </Box>
                  )
                })}

                <Box
                  sx={{
                    height: '150px',
                    width: '356px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '10px',
                    position: 'relative',
                    p: 3,
                    background: 'linear-gradient(290deg, #0042CD 7.81%, #00277A 99.91%)',
                  }}
                >
                  <Box width={146}>
                    <Typography color="white" fontSize="18px" fontWeight={600}>
                      {content.tile.title}
                    </Typography>
                  </Box>
                  <Box position={'absolute'} width="100px" right="24px" bottom={0}>
                    <Image media={content.tile.image as Strapi__Media} style={{ objectFit: 'contain' }} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
