import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { Box, Container } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { SectionHeader } from '../../../components/section-header'
import { NftPageQuery } from 'gatsby-graphql'
import NftV2RichText from '../../../components/nftv2-rich-text'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }),
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  '&.Mui-expanded': {
    backgroundColor: '#C2D6FF',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

type Props = {
  title: string
  description: NftPageQuery['strapiNft']['cashbackFAQDescription']
  contents: NftPageQuery['strapiNft']['CashbackFAQ']
}

export const FaqAccordion: React.FC<Props> = ({ title, description, contents }) => {
  return (
    <Container>
      <Box pb={{ xs: 2, sm: 2, md: 3 }}>
        <SectionHeader title={title} description={description} textAlign="center" />
      </Box>
      {contents.map((content) => (
        <Accordion key={content.id} sx={{ marginBottom: 0.5, border: 'none' }}>
          <AccordionSummary expandIcon={<AddIcon />} aria-controls={content.question} id={content.id}>
            <Typography variant="h6" fontSize={{ xs: 16, sm: 18, lg: 20 }} fontWeight={500}>
              {content.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={{ xs: 14, sm: 16, lg: 18 }}>
              <NftV2RichText markdown={content.answer.data.answer} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  )
}
