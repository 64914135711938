import { Alert, AlertTitle, Container } from '@mui/material'
import React, { FC } from 'react'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'

type Props = {
  title: string
  description: string
}

export const ImportantAlert: FC<Props> = ({ title, description }) => {
  return (
    <Container sx={{ px: { xs: '8px', sm: '16px', lg: '24px' } }}>
      <Alert icon={<ReportProblemOutlinedIcon />} severity="error">
        <AlertTitle>{title}</AlertTitle>
        {description}
      </Alert>
    </Container>
  )
}
