import { Box, Typography, useMediaQuery } from '@mui/material'
import { NftPageQuery, Strapi__Media } from 'gatsby-graphql'
import React, { FC } from 'react'
import { aboutSectionTransformer } from '../../transforms/about-section-transformer'
import Image from '@/components/image'
import { v2theme } from 'src/theme/ThemeProvider'
import { css } from '@emotion/css'

type Props = {
  contents: NftPageQuery['strapiNft']['NftAbout']
}

export const AboutSectionDesktop: FC<Props> = ({ contents }) => {
  const { title, background, icon, video } = aboutSectionTransformer(contents)
  const isSmToMd = useMediaQuery(v2theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(v2theme.breakpoints.up('md'))

  return (
    <Box height={isSmToMd ? '480px' : '520px'}>
      <Box display="flex" flexDirection="row" height={isSmToMd ? '420px' : '520px'}>
        <Box width="50%" position="relative" zIndex={10}>
          <iframe
            className={css`
              position: absolute;
              top: 60%;
              left: 50%;
              width: 105%;
              height: 100%;
              transform: translate(-50%, -50%);
              border: none;

              @media (max-aspect-ratio: 16/9) {
                width: 177.78vh;
              }
            `}
            src={video}
            style={{ border: 'none' }}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Video"
          ></iframe>
        </Box>

        <Box
          sx={{
            backgroundImage: `url(${background.localFile.publicURL})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '50%',
            height: isSmToMd ? '380px' : '520px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMdUp ? 'end' : 'center',
              justifyContent: isMdUp ? 'end' : 'center',
            }}
          >
            <Image media={icon as Strapi__Media} style={{ objectFit: 'contain', height: '120px' }} />
            <Box width="280px">
              <Typography component="h4" align="center" fontSize="30px" fontWeight="bold" color="white">
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AboutSectionDesktop
