import React from 'react'
import { NftPageQuery } from 'gatsby-graphql'

import { Box, Alert, Button as MuiButton, CircularProgress } from '@mui/material'
import { useAccount, useBalance, useDisconnect, useContractReads, useNetwork, useSwitchNetwork } from 'wagmi'
import { useContractCall } from '@/utils/web3'
import { MintPanel } from './mint-widget/mint-panel'
import { MintSection } from '../sections/nft-v2/views/mint-section-view'

import { ConnectWalletButton } from './mint-widget/connect-wallet-button'
import { IStatusGateProps } from './mint-widget/mint-interface'
import { AllowlistGate } from './mint-widget/allow-list-gate'

export type Props = {
  contentV2: NftPageQuery['strapiNft']['NftMintV2']
  contentV1: NftPageQuery['strapiNft']['MintSection']
}

export interface IConnectButtonProps {
  label: string
}

export interface IErrorPanelProps {
  message: string
  action?: React.ReactElement
}

const ErrorPanel = ({ message, action }: IErrorPanelProps) => {
  return (
    <Box sx={{ width: { xs: '100%' }, margin: 'auto' }}>
      <Alert severity="error" action={action}>
        {message}
      </Alert>
    </Box>
  )
}

const StatusGate = ({
  supply,
  totalSupply,
  mintingOpen,
  softCap,
  softCapEnforced,
  price,
  errorMessages,
  children,
  labels,
  connectWalletToc,
}: IStatusGateProps) => {
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()
  const { isConnected, address } = useAccount()
  const { disconnect } = useDisconnect()
  const { isLoading: isLoadingBalance, data: balance } = useBalance({
    address,
    watch: true,
  })

  const requiredChainId = parseInt(process.env.GATSBY_CONTRACT_CHAIN_ID!, 10)
  const isCorrectChain = chain?.id === requiredChainId

  // Check that the supply has not sold out
  if (supply === totalSupply) {
    return <ErrorPanel message={errorMessages.SoldOutError} />
  }

  // Check mint is open and the soft cap hasn't been reached
  if (!mintingOpen || (softCapEnforced && supply >= softCap)) {
    return <ErrorPanel message={errorMessages.MintingPausedError} />
  }

  // Check users wallet is connected
  if (!isConnected) {
    return <ConnectWalletButton connectWalletToc={connectWalletToc} />
  }

  // Check they're on the correct chain
  if (!isCorrectChain) {
    return (
      <ErrorPanel
        message={errorMessages.WrongBlockchainError}
        action={
          <MuiButton
            variant="outlined"
            sx={{ flexShrink: 0 }}
            color="inherit"
            size="small"
            onClick={() => switchNetwork?.(requiredChainId)}
          >
            {labels.SwitchChain}
          </MuiButton>
        }
      />
    )
  }

  if (!balance && isLoadingBalance) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  // Check they have sufficient balance
  if (balance && balance.value < price) {
    return (
      <ErrorPanel
        message={errorMessages.InsufficientBalanceError}
        action={
          <MuiButton
            variant="outlined"
            sx={{ flexShrink: 0 }}
            color="inherit"
            size="small"
            onClick={() => disconnect()}
          >
            {labels.Disconnect}
          </MuiButton>
        }
      />
    )
  }

  return children
}

const MintWidget = (props: Props) => {
  // Merkle proof ID
  const merkleId = process.env.GATSBY_MERKLE_ID!

  // Extract error messages from Strapi data
  const errorMessages: Record<string, string> = Object.entries(props.contentV1).reduce(
    (acc: Record<string, string>, [key, value]) => {
      if (key.endsWith('Error')) {
        acc[key] = (value as any).data[key]
      }
      return acc
    },
    {},
  )
  // Get the basic contract status
  const { contractRead } = useContractCall()
  const { data, isLoading } = useContractReads({
    watch: true,
    contracts: [
      contractRead('totalSupply'),
      contractRead('collectionSize'),
      contractRead('getPrice', [BigInt(1) as never]),
      // contractRead('PROVIDER_FEE'),
      contractRead('mintingOpen'),
      contractRead('onlyAllowlistMode'),
      contractRead('softCap'),
      contractRead('softCapEnforced'),
    ],
  })

  const [
    { result: supply } = { result: BigInt(0) }, // Minted tokens
    { result: totalSupply } = { result: BigInt(0) }, // Maximum supply
    { result: price } = { result: BigInt(0) }, // Current mint fee
    // { result: providerFee } = { result: BigInt(0) }, // Per-tx provider fee
    { result: mintingOpen } = { result: false }, // Master minting control
    { result: onlyAllowlistMode } = { result: false }, // Requires allowlist to mint
    { result: softCap } = { result: BigInt(0) }, // Soft cap of tokens
    { result: softCapEnforced } = { result: false }, // Whether the cap is enforced
  ] = data ?? []

  const hasFailure = (data || []).reduce((acc, cur) => (acc = cur.status === 'failure' || acc), false)
  const WidgetUrl = process.env.GATSBY_WIDGET_URL

  if (hasFailure) {
    return (
      <Box sx={{ width: { xs: '100%', md: '472px' }, margin: 'auto' }}>
        <Alert severity="error">{errorMessages.UnexpectedError}</Alert>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <MintSection content={props.contentV2} contact={{ supply, totalSupply, price }}>
        <StatusGate
          supply={supply}
          totalSupply={totalSupply}
          mintingOpen={mintingOpen}
          softCap={softCap}
          softCapEnforced={softCapEnforced}
          price={price}
          errorMessages={errorMessages}
          labels={props.contentV1.Labels}
          connectWalletToc={props.contentV2.connectWalletTermAndCondition.data.connectWalletTermAndCondition}
        >
          <AllowlistGate
            enabled={onlyAllowlistMode}
            merkleId={merkleId}
            errorMessages={errorMessages}
            labels={props.contentV1.Labels}
            WidgetUrl={WidgetUrl as string}
          >
            {(merkleProof) => (
              <MintPanel
                price={price}
                onlyAllowlistMode={onlyAllowlistMode}
                merkleProof={merkleProof}
                mintSection={props.contentV1}
                mintToc={props.contentV2.mintTermAndCondition.data.mintTermAndCondition}
              />
            )}
          </AllowlistGate>
        </StatusGate>
      </MintSection>
    </>
  )
}

export default MintWidget
