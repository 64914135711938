import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { NftPageQuery } from 'gatsby-graphql'
import React, { FC } from 'react'
import { SectionHeader } from '../../../components/section-header'
import { Asterisk } from './asterick'

type Props = {
  title: NftPageQuery['strapiNft']['NftMoreInformation']['cashbackTitle']
  tableHeader: NftPageQuery['strapiNft']['NftMoreInformation']['NftCashbackTableHeader']
  description: NftPageQuery['strapiNft']['NftMoreInformation']['cashbackDescription']
  shortDescription: NftPageQuery['strapiNft']['NftMoreInformation']['cashbackMoreInformation']
  table: NftPageQuery['strapiNft']['NftMoreInformation']['NftPriceAndCashbackTable']
}

export const CashbackInfo: FC<Props> = ({ title, description, shortDescription, table, tableHeader }) => {
  return (
    <>
      <Box pb={{ xs: 2, sm: 2, md: 3 }}>
        <SectionHeader title={title} description={description} textAlign="center" />
      </Box>

      <Container sx={{ px: { xs: '8px', sm: '16px', lg: '24px' } }}>
        <TableContainer sx={{ borderRadius: 0, display: { xs: 'block', sm: 'none' } }} component={Paper} elevation={0}>
          <Table sx={{ borderCollapse: 'separate' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ borderTopLeftRadius: '16px', width: '50%', background: '#0042CD' }}>
                  <Typography fontSize="20px" color="white" fontWeight={700}>
                    {tableHeader.title}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ borderTopRightRadius: '16px', background: '#27BE8C' }}>
                  <Typography fontSize="20px" color="white" fontWeight={700}>
                    {tableHeader.value}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table.map((row, index) => (
                <TableRow key={row.range}>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottomLeftRadius: table.length - 1 === index ? '16px' : '0px',
                      border: '0.5px solid #C2D6FF',
                      background: '#FBFDFF',
                    }}
                  >
                    <Typography fontSize={{ xs: '16px', sm: '16px', md: '18px' }} fontWeight={400}>
                      {row.range}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderBottomRightRadius: table.length - 1 === index ? '16px' : '0px',
                      border: '0.75px solid #ADDCC0',
                      background: '#EAF6EF',
                    }}
                  >
                    <Typography fontSize={{ xs: '16px', sm: '16px', md: '18px' }} fontWeight={400}>
                      {row.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer sx={{ borderRadius: 0, display: { xs: 'none', sm: 'block' } }} component={Paper} elevation={0}>
          <Table sx={{ borderCollapse: 'separate' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ borderTopLeftRadius: '16px', background: '#0042CD' }}>
                  <Typography fontSize="20px" color="white" fontWeight={700}>
                    {tableHeader.title}
                  </Typography>
                </TableCell>
                {table.map((row, index) => (
                  <TableCell
                    align="center"
                    sx={{
                      borderTopRightRadius: table.length - 1 === index ? '16px' : '0px',
                      border: '0.5px solid #C2D6FF',
                      background: '#FBFDFF',
                    }}
                  >
                    <Typography fontSize={{ xs: '14px', sm: '16px', md: '18px' }} fontWeight={400}>
                      {row.range}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell align="center" sx={{ borderBottomLeftRadius: '16px', background: '#27BE8C' }}>
                <Typography fontSize="20px" color="white" fontWeight={700}>
                  {tableHeader.value}
                </Typography>
              </TableCell>
              {table.map((row, index) => (
                <TableCell
                  align="center"
                  sx={{
                    borderBottomRightRadius: table.length - 1 === index ? '16px' : '0px',
                    border: '0.75px solid #ADDCC0',
                    background: '#EAF6EF',
                  }}
                >
                  <Typography fontSize={{ xs: '14px', sm: '16px', md: '18px' }} fontWeight={400}>
                    {row.value}
                  </Typography>
                </TableCell>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ pt: 1, xr: -2 }}>
          <Asterisk currentStep={1} content={shortDescription.data.cashbackMoreInformation} />
        </Box>
      </Container>
    </>
  )
}
