import { useEffect, useRef, useState, useCallback } from 'react'
import debounce from 'lodash/debounce'

const useRefHeight = (debounceTime = 200) => {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  const updateHeight = useCallback(
    debounce(() => {
      if (ref.current) {
        setHeight(ref.current.offsetHeight)
      }
    }, debounceTime),
    [debounceTime],
  )

  useEffect(() => {
    updateHeight()

    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
      updateHeight.cancel()
    }
  }, [updateHeight])

  return { ref, height }
}

export default useRefHeight
