import { NftPageQuery } from 'gatsby-graphql'

type PerkSection = {
  title: string
  description: string
  adsTitle: string
  adsDescription: string
  informationButton: NftPageQuery['strapiNft']['NftPerk']['informationButton']
  mobileBackgroundImage: NftPageQuery['strapiNft']['NftPerk']['mobileBackgroundImage']
  desktopBackgroundImage: NftPageQuery['strapiNft']['NftPerk']['mobileBackgroundImage']
  video: string
  perks: NftPageQuery['strapiNft']['NftPerk']['perks']
}

export const perkSectionTransformer = (data: NftPageQuery['strapiNft']['NftPerk']): PerkSection => {
  return {
    title: data.title,
    description: data.description,
    adsTitle: data.adsTitle,
    adsDescription: data.adsDescription,
    informationButton: data.informationButton,
    mobileBackgroundImage: data.mobileBackgroundImage,
    desktopBackgroundImage: data.desktopBackgroundImage,
    video: data.video,
    perks: data.perks,
  }
}
