import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ChevronRightIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 14" strokeWidth={1} stroke="currentColor">
      <title>Right Arrow</title>
      <g id="Artboards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop_Home" transform="translate(-359.000000, -602.000000)" fill="currentColor" fillRule="nonzero">
          <g id="Right-Arrow" transform="translate(359.000000, 602.000000)">
            <path
              d="M7.71646546,7.69046018 L1.68199321,13.7080133 C1.29812789,14.091 0.675756063,14.091 0.292077094,13.7080133 C-0.0916329333,13.3253673 -0.0916329333,12.7047522 0.292077094,12.3221372 L5.6316534,6.99752212 L0.292232392,1.67309292 C-0.0914776354,1.29029204 -0.0914776354,0.669738938 0.292232392,0.28709292 C0.67594242,-0.0957079646 1.29828319,-0.0957079646 1.68214851,0.28709292 L7.71662076,6.30473894 C7.90847578,6.49615487 8.00429457,6.74676106 8.00429457,6.99749115 C8.00429457,7.24834513 7.90828942,7.49913717 7.71646546,7.69046018 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
)

export default ChevronRightIcon
