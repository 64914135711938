import { NftPageQuery } from 'gatsby-graphql'
import React, { FC } from 'react'
import { SectionHeader } from '../../components/section-header'
import { Box, Container, Grid, useMediaQuery } from '@mui/material'
import { perkFeatureSectionTransformer } from '../../transforms/perk-feature-transformer'
import { Card } from './card'
import { Step } from './step'
import { v2theme } from 'src/theme/ThemeProvider'

type Props = {
  contents: NftPageQuery['strapiNft']['NftFeaturePerk']
}

export const PerkFeatureSection: FC<Props> = ({ contents }) => {
  const { title, description, cards, steps } = perkFeatureSectionTransformer(contents)
  const islgDown = useMediaQuery(v2theme.breakpoints.down('lg'))
  return (
    <Box
      py={islgDown ? 5 : 10}
      sx={{
        background: (theme) => theme.palette.background.light,
        boxShadow: (theme) => `0 0 0 100vmax ${theme.palette.background.light}`,
        clipPath: 'inset(0 -100vmax)',
      }}
    >
      <Box pb={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box minWidth={{ sx: '400px', sm: '700' }} maxWidth="600px" mb={4}>
          <SectionHeader title={title} description={description} textAlign="center" />
        </Box>
        <Grid container gap={2} alignItems="stretch" justifyContent="center">
          {cards.map((card) => {
            return (
              <Grid key={card.id} item xs={12} sm={4} md={4} lg={2} xl={2}>
                <Card {...card} />
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <Container>
        <Box my={1}>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent={{ xs: 'start', sm: 'center' }}
            flexWrap="wrap"
          >
            {steps.map((step, index) => {
              return <Step key={step.Text.data.id} currentStep={index + 1} {...step} />
            })}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
