import { Box, StepLabel, Typography } from '@mui/material'
import React, { FC } from 'react'
import NftV2RichText from '../../../components/nftv2-rich-text'

type Props = {
  content: string
  currentStep: number
}

export const Asterisk: FC<Props> = ({ currentStep, content }) => {
  return (
    <Box>
      {/* <StepLabel
        sx={{ display: 'flex', alignItems: 'start', width: '100%' }}
        StepIconComponent={() => (
          <Box
            border="1px solid #CBD1E1"
            borderRadius={1.5}
            sx={{
              background: 'white',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              width: 28,
              height: 28,
            }}
          >
            <Typography variant="h6" fontSize="14px" fontWeight="bold" sx={{ opacity: 0.62 }}>
              {currentStep === 1 ? '*' : currentStep === 2 ? '**' : '***'}
            </Typography>
          </Box>
        )}
      > */}
      <NftV2RichText markdown={content} />
      {/* </StepLabel> */}
    </Box>
  )
}
