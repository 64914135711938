import { useMediaQuery } from '@mui/material'
import { NftPageQuery } from 'gatsby-graphql'
import React, { FC } from 'react'
import { v2theme } from 'src/theme/ThemeProvider'
import { MobileHeroSection, DesktopHeroSection } from '.'

type Props = {
  contents: NftPageQuery['strapiNft']['Nft']
}

export const HeroSection: FC<Props> = ({ contents }) => {
  const isSmDown = useMediaQuery(v2theme.breakpoints.down('sm'))
  const isSmUp = useMediaQuery(v2theme.breakpoints.up('sm'))

  return (
    <section id="hero-section">
      {isSmUp && <DesktopHeroSection contents={contents} />}
      {isSmDown && <MobileHeroSection contents={contents} />}
    </section>
  )
}
