import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const ConnectIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_484_7318)">
      <path
        d="M8.33301 10.834C8.69088 11.3124 9.14747 11.7083 9.6718 11.9947C10.1961 12.2812 10.7759 12.4516 11.3719 12.4942C11.9678 12.5369 12.566 12.4509 13.1258 12.2421C13.6856 12.0333 14.1939 11.7065 14.6163 11.284L17.1163 8.78396C17.8753 7.99811 18.2953 6.9456 18.2858 5.85312C18.2763 4.76063 17.8381 3.71558 17.0656 2.94304C16.2931 2.17051 15.248 1.73231 14.1555 1.72281C13.063 1.71332 12.0105 2.1333 11.2247 2.89229L9.79134 4.31729"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M11.6668 9.16702C11.309 8.68858 10.8524 8.2927 10.328 8.00623C9.80371 7.71977 9.22391 7.54942 8.62796 7.50674C8.03201 7.46406 7.43384 7.55004 6.87405 7.75887C6.31425 7.96769 5.8059 8.29446 5.3835 8.71702L2.8835 11.217C2.12451 12.0029 1.70453 13.0554 1.71402 14.1479C1.72352 15.2403 2.16172 16.2854 2.93426 17.0579C3.70679 17.8305 4.75184 18.2687 5.84433 18.2782C6.93681 18.2877 7.98932 17.8677 8.77517 17.1087L10.2002 15.6837"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_484_7318">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default ConnectIcon
