import { Box, Container, useMediaQuery } from '@mui/material'
import { NftPageQuery } from 'gatsby-graphql'
import React, { FC } from 'react'
import { v2theme } from 'src/theme/ThemeProvider'
import { AboutSectionMobile, AboutSectionDesktop } from '.'

type Props = {
  contents: NftPageQuery['strapiNft']['NftAbout']
}

export const AboutSection: FC<Props> = ({ contents }) => {
  const isMdDown = useMediaQuery(v2theme.breakpoints.down('md'))
  const isMdUp = useMediaQuery(v2theme.breakpoints.up('md'))

  return (
    <section id="about-section">
      {isMdDown && <AboutSectionMobile contents={contents} />}
      {isMdUp && (
        <Box bgcolor={(theme) => theme.palette.background.light}>
          <Container maxWidth="xl" disableGutters>
            <AboutSectionDesktop contents={contents} />
          </Container>
        </Box>
      )}
    </section>
  )
}
