import { NftPageQuery } from 'gatsby-graphql'

type PerkFeatureSection = {
  title: string
  description: string
  cards: NftPageQuery['strapiNft']['NftFeaturePerk']['cards']
  steps: NftPageQuery['strapiNft']['NftFeaturePerk']['steps']
}

export const perkFeatureSectionTransformer = (
  data: NftPageQuery['strapiNft']['NftFeaturePerk'],
): PerkFeatureSection => {
  return {
    title: data.title,
    description: data.description,
    cards: data.cards,
    steps: data.steps,
  }
}
