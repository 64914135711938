import React, { FC, useState } from 'react'
import { navigate } from 'gatsby'
import { Box, Alert, AlertTitle, CircularProgress, Button, FormControlLabel, Checkbox } from '@mui/material'
import { useAccount, useContractWrite, useWaitForTransaction } from 'wagmi'
import { useContractCall } from '@/utils/web3'
import { IMintWidgetProps } from './mint-interface'
import ChevronRightIcon from '../mui-icon/chevron-right'
import ConnectIcon from '../mui-icon/connect'
import RichText from '../rich-text'

export const MintPanel: FC<
  Pick<IMintWidgetProps, 'mintSection' | 'price' | 'onlyAllowlistMode' | 'merkleProof'> & { mintToc: string }
> = ({ mintSection, price, onlyAllowlistMode, merkleProof, mintToc }) => {
  const { address } = useAccount()
  const [termsAccepted, setTermsAccepted] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked)
  }

  // Prepare the mint call
  const code = localStorage.getItem('mint_code')
  const mintFunction = onlyAllowlistMode
    ? code
      ? 'mintToMultipleALAF'
      : 'mintToMultipleAL'
    : code
    ? 'mintToMultipleAF'
    : 'mintToMultiple'

  const mintArgs = (() => {
    switch (mintFunction) {
      case 'mintToMultipleALAF':
        return [address, 1, merkleProof, code]
      case 'mintToMultipleAL':
        return [address, 1, merkleProof]
      case 'mintToMultipleAF':
        return [address, 1, code]
      default:
        return [address, 1]
    }
  })()

  console.log(`Using minting method: ${mintFunction}, ${mintArgs}`)

  // Prepare the contract write
  const { prepareContractWrite } = useContractCall()
  const { config } = prepareContractWrite(
    mintFunction,
    mintArgs as never,
    // Request overrides (set payable fee)
    {
      value: price, // + providerFee,
    },
  )

  const { write: writeMint, data: writeData, isLoading: isMinting } = useContractWrite(config)
  const { isLoading: isWaitingOnTx } = useWaitForTransaction({
    hash: writeData?.hash,
    onSuccess() {
      navigate(`/welcome-mafioso?tx=${writeData?.hash}`)
    },
  })

  const [shakeTOS, setShakeTOS] = useState(false)

  const handleShake = () => {
    if (termsAccepted) {
      return
    }
    setShakeTOS(true)
    setTimeout(() => {
      setShakeTOS(false)
    }, 1000)
  }
  if (isMinting) {
    return (
      <>
        <Box sx={{ width: { xs: '100%' }, margin: 'auto' }}>
          <Alert severity="info" icon={<CircularProgress size="32px" />}>
            <AlertTitle sx={{ fontWeight: 'bold' }}>{mintSection.MintingConfirmationAlertTitle}</AlertTitle>
            {mintSection.MintingConfirmationAlertText}
          </Alert>
        </Box>
      </>
    )
  }

  if (isWaitingOnTx) {
    return (
      <>
        <Box sx={{ width: { xs: '100%' }, margin: 'auto' }}>
          <Alert severity="info" icon={<CircularProgress size="32px" />}>
            <AlertTitle sx={{ fontWeight: 'bold' }}>{mintSection.WaitingOnTxAlertTitle}</AlertTitle>
            {mintSection.WaitingOnTxAlertText}
          </Alert>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box
        sx={{ width: { xs: '100%' } }}
        onClick={() => {
          //onClick added here because we want to add animation when clicking a disabled button
          handleShake()
        }}
      >
        <Box py={1}>
          <Button
            sx={{ background: '#45D9A8' }}
            variant="contained"
            disabled={!termsAccepted}
            onClick={() => writeMint?.()}
            endIcon={<ChevronRightIcon sx={{ width: '12px', height: '12px' }} />}
            startIcon={<ConnectIcon sx={{ width: '16px', fontSize: '16px' }} />}
          >
            {mintSection.MintButtonText}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          '& input': {
            width: { md: '24px', xs: '32px' },
            height: { md: '24px', xs: '32px' },
          },

          transition: 'transform 0.5s ease-in-out',
          animation: shakeTOS ? 'shake 0.5s ease' : 'none',
          '@keyframes shake': {
            '0%': {
              transform: 'translateX(0)',
            },
            '20%': {
              transform: 'translateX(-5px)',
            },
            '40%': {
              transform: 'translateX(5px)',
            },
            '60%': {
              transform: 'translateX(-3px)',
            },
            '80%': {
              transform: 'translateX(3px)',
            },
            '100%': {
              transform: 'translateX(0)',
            },
          },
        }}
      >
        <Box>
          <FormControlLabel
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            value="end"
            control={
              <Checkbox
                color="primary"
                sx={{
                  pl: '-10px',
                  color: 'white',
                  '&.Mui-checked': {
                    color: 'white',
                  },

                  '&.MuiCheckbox-root': {
                    color: 'white',
                  },
                }}
                checked={termsAccepted}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            color="white"
            label={
              <Box maxWidth={300}>
                <RichText sx={{ color: 'white' }} hyperlinkColor="#1F9870" markdown={mintToc} />
              </Box>
            }
            labelPlacement="end"
          />
        </Box>
      </Box>
    </>
  )
}
