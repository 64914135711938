import { css } from '@emotion/css'
import { Box, Button, Typography } from '@mui/material'
import { NftPageQuery, Strapi__Media } from 'gatsby-graphql'
import React, { FC } from 'react'
import Image from '@/components/image'
import { perkSectionTransformer } from '../../transforms/perk-section-transformer'
import { SectionHeader } from '../../components/section-header'
import { Perks } from './perk/perk'

type Props = {
  contents: NftPageQuery['strapiNft']['NftPerk']
  onClick: () => void
}
export const MobilePerkSection: FC<Props> = ({ contents, onClick }) => {
  const { title, description, adsTitle, adsDescription, video, mobileBackgroundImage, informationButton, perks } =
    perkSectionTransformer(contents)

  return (
    <Box
      className={css`
        height: 860px;
        padding-left: 16px;
        margin-top: 50px;
        margin-bottom: 50px;
      `}
    >
      <Box maxWidth="300px" margin="auto">
        <SectionHeader title={title} description={description} textAlign="center" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Box position={'relative'} height={450}>
          <Image media={mobileBackgroundImage as Strapi__Media} style={{ objectFit: 'cover', height: '400px' }} />

          <Box
            width={160}
            position={'absolute'}
            left="0px"
            top="0px"
            sx={{
              backgroundColor: 'white',
              transform: 'translate(0%, 70%)',
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '30px',
              }}
            >
              {adsTitle}
            </Typography>
            <Typography gutterBottom fontWeight={'700'} color={'#0C111F'}>
              {adsDescription}
            </Typography>
            <Button onClick={onClick} variant="outlined" color="primary" sx={{ background: 'white' }}>
              {informationButton.text}
            </Button>
          </Box>
          <Box
            width={170}
            height={170}
            position={'absolute'}
            left="50%"
            top="50%"
            sx={{
              transform: 'translate(-28%, -63%)',
            }}
          >
            <iframe
              className={css`
                width: 100%;
                height: 100%;
              `}
              src={video}
              style={{ border: 'none' }}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Video"
            ></iframe>
          </Box>
        </Box>
      </Box>
      <Perks perks={perks} />
    </Box>
  )
}
