import Image from '@/components/image'
import { Box, Stepper, Step, StepLabel, Typography, StepContent, Container } from '@mui/material'
import { NftPageQuery, Strapi__Media } from 'gatsby-graphql'
import React, { FC } from 'react'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { IconButton } from '@mui/material'
import NftV2RichText from '../../../components/nftv2-rich-text'
import { SectionHeader } from '../../../components/section-header'
import { SequenceNumber } from './sequence-number'

type Props = {
  title: NftPageQuery['strapiNft']['NftMoreInformation']['AfterPurchasingTitle']
  step1: NftPageQuery['strapiNft']['NftMoreInformation']['AfterPurchasingSteps1']
  step2: NftPageQuery['strapiNft']['NftMoreInformation']['AfterPurchasingSteps2']
  step3: NftPageQuery['strapiNft']['NftMoreInformation']['AfterPurchasingSteps3']
  step4: NftPageQuery['strapiNft']['NftMoreInformation']['AfterPurchasingSteps4']
  termsAndCondition: NftPageQuery['strapiNft']['NftMoreInformation']
  links: NftPageQuery['strapiNft']['NftMoreInformation']['links']
}

function QontoStepIcon() {
  return (
    <Box
      sx={{
        alignSelf: 'center',
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    />
  )
}

const LinkToBox: FC<{
  image: NftPageQuery['strapiNft']['NftMoreInformation']['links'][0]['image']
  link: string
}> = ({ image, link }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ borderRadius: 2, p: 1, background: (theme) => theme.palette.background.baseLight }}
    >
      <Box display="flex" justifyContent="left" alignItems="center">
        <Box width={54} display="flex" justifyContent="center" p={1} borderRadius={2} bgcolor="white">
          <Image media={image as Strapi__Media} />
        </Box>
        <Typography sx={{ marginLeft: 1 }} fontSize={12}>
          {link}
        </Typography>
      </Box>

      <IconButton target="_blank" color="primary" href={link} sx={{ padding: 0.5 }}>
        <OpenInNewOutlinedIcon fontSize="small" />
      </IconButton>
    </Box>
  )
}

export const AfterPurchasingStep: FC<Props> = ({ title, step1, step2, step3, step4, termsAndCondition, links }) => {
  return (
    <Container sx={{ px: { xs: '8px', sm: '16px', lg: '24px' } }}>
      <SectionHeader title={title} textAlign="center" />
      <Stepper orientation="vertical">
        <Box p={1}>
          <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
        </Box>
        <Step active key="step1">
          <StepLabel sx={{ display: 'flex', alignItems: 'start' }} icon={1}>
            <NftV2RichText sx={{ mt: -0.2 }} markdown={step1.title.data.title} />
          </StepLabel>
          <StepContent>
            <NftV2RichText markdown={step1.description.data.description} />
            <Box
              p={2}
              sx={{
                background: (theme) => theme.palette.background.baseLight,
              }}
            >
              <NftV2RichText markdown={step1.importantNote.data.importantNote} />
            </Box>
            <NftV2RichText markdown={step1.linkDescription.data.linkDescription} />
            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1}>
              {links.map((link) => (
                <LinkToBox key={link.id} image={link.image} link={link.link} />
              ))}
            </Box>
          </StepContent>
        </Step>

        <Step active key="step2">
          <StepLabel sx={{ display: 'flex', alignItems: 'start' }} icon={2}>
            <NftV2RichText sx={{ mt: -0.2 }} markdown={step2.title.data.title} />
          </StepLabel>
          <StepContent>
            <NftV2RichText markdown={step2.description.data.description} />
          </StepContent>
        </Step>

        <Step active key="step3">
          <StepLabel sx={{ display: 'flex', alignItems: 'start' }} icon={3}>
            <NftV2RichText sx={{ mt: -0.2 }} markdown={step3.title.data.title} />
          </StepLabel>
          <StepContent>
            <NftV2RichText markdown={step3.description.data.description} />
          </StepContent>
        </Step>

        <Step active key="step4">
          <StepLabel sx={{ display: 'flex', alignItems: 'start' }} icon={4}>
            <NftV2RichText sx={{ mt: -0.2 }} markdown={step4.title.data.title} />
          </StepLabel>
          <StepContent>
            <NftV2RichText markdown={step4.description.data.description} />
            <Box
              p={2}
              sx={{
                background: (theme) => theme.palette.background.baseLight,
              }}
            >
              <NftV2RichText markdown={step4.importantNote.data.importantNote} />
            </Box>
          </StepContent>
        </Step>
        <Box p={1}>
          <StepLabel sx={{ display: 'flex', alignItems: 'start' }} StepIconComponent={QontoStepIcon}>
            <Typography variant="h6" fontSize="16px" fontWeight="bold">
              {termsAndCondition.TermAndCondition}
            </Typography>
            {termsAndCondition.SequenceTermAndCondition.map((term, index) => (
              <SequenceNumber key={index} currentStep={index + 1} content={term.Content.data.Content} />
            ))}
          </StepLabel>
        </Box>
      </Stepper>
    </Container>
  )
}
