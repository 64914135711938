import RichText from '@/components/elements/rich-text'
import { Box, Chip } from '@mui/material'
import { NftPageQuery } from 'gatsby-graphql'
import React, { FC } from 'react'

type Props = {
  perks: NftPageQuery['strapiNft']['NftPerk']['perks']
}

export const Perks: FC<Props> = ({ perks }) => {
  return (
    <>
      {perks.map((perk, index) => (
        <Box height={{ xs: 'auto', sm: '125px', md: '100px' }} key={perk.Content.data.id}>
          <Chip
            sx={{ maxWidth: '120px', fontSize: '18px', fontWeight: 700 }}
            label={`Perk-0${index + 1}`}
            color="primary"
            size="medium"
          />
          <Box mt={-2}>
            <RichText markdown={perk.Content.data.Content} hyperlinkColor={'#419864'} />
          </Box>
        </Box>
      ))}
    </>
  )
}
