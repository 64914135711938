import React, { useState, useEffect, FC } from 'react'
import { Box, Button as MuiButton, CircularProgress } from '@mui/material'
import { useAccount, useDisconnect } from 'wagmi'
import { useFetchPost } from '@/utils/hooks'
import { ErrorPanel } from './error-panel'
import { IAllowlistGateProps } from './mint-interface'

export const AllowlistGate: FC<IAllowlistGateProps> = ({
  enabled,
  WidgetUrl,
  merkleId,
  errorMessages,
  children,
  labels,
}) => {
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const [isAllowlisted, setIsAllowlisted] = useState(false)
  const [merkleLeaf, setMerkleLeaf] = useState(undefined)
  const [submit, data, loading] = useFetchPost(`${WidgetUrl}/allowlist/${merkleId}/merkle/verify`)

  useEffect(() => {
    if (address && enabled) {
      submit({ claimAddress: address.toLowerCase() })
    }
  }, [address, enabled])

  useEffect(() => {
    if (data?.merkleProof) {
      setIsAllowlisted(true)
      setMerkleLeaf(data.merkleProof)
    } else {
      setIsAllowlisted(false)
      setMerkleLeaf(undefined)
    }
  }, [data])

  if (!enabled) {
    return children()
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  if (!isAllowlisted) {
    return (
      <ErrorPanel
        message={errorMessages.NotOnWhitelistError}
        action={
          <MuiButton sx={{ flexShrink: 0 }} color="inherit" size="small" onClick={() => disconnect()}>
            {labels.Disconnect}
          </MuiButton>
        }
      />
    )
  }

  return children(merkleLeaf)
}
