import { NftPageQuery } from 'gatsby-graphql'

type PerkSection = {
  title: string
  background: NftPageQuery['strapiNft']['NftAbout']['background']
  icon: NftPageQuery['strapiNft']['NftAbout']['icon']
  video: string
}

export const aboutSectionTransformer = (data: NftPageQuery['strapiNft']['NftAbout']): PerkSection => {
  return {
    title: data.title,
    background: data.background,
    icon: data.icon,
    video: data.video,
  }
}
