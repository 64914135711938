import { Alert, Box } from '@mui/material'
import { IErrorPanelProps } from './mint-interface'

export const ErrorPanel = ({ message, action }: IErrorPanelProps) => {
  return (
    <Box sx={{ width: { xs: '100%' }, margin: 'auto' }}>
      <Alert severity="error" action={action}>
        {message}
      </Alert>
    </Box>
  )
}
