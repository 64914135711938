import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import ReactMarkdown from 'react-markdown'
import { Button, DialogActions, useMediaQuery } from '@mui/material'
import { v2theme } from 'src/theme/ThemeProvider'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

type Props = {
  title: string
  description: string
  open: boolean
  onOpen: () => void
  onClose: () => void
}

export const InformationDialog: React.FC<Props> = ({ open, onClose, title = '', description = '' }) => {
  const isSmDown = useMediaQuery(v2theme.breakpoints.down('sm'))

  return (
    <React.Fragment>
      <BootstrapDialog
        PaperProps={{ sx: { borderRadius: '12px' } }}
        onClose={onClose}
        aria-labelledby="information-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            border: 'none',
            m: 0,
            p: 2,
            fontWeight: 600,
            backgroundColor: (theme) => theme.palette.background.light,
          }}
          id="information-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <ReactMarkdown children={description} />
        </DialogContent>
        <DialogActions sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
          <Button sx={{ px: 8 }} size="large" fullWidth={isSmDown} variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  )
}
