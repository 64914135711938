import { Box, StepLabel, Typography } from '@mui/material'
import React, { FC } from 'react'
import NftV2RichText from '../../../components/nftv2-rich-text'

type Props = {
  content: string
  currentStep: number
}

export const SequenceNumber: FC<Props> = ({ content, currentStep }) => {
  return (
    <Box>
      <StepLabel
        sx={{ alignItems: 'start', width: '100%' }}
        StepIconComponent={() => (
          <Box
            border="1px solid #CBD1E1"
            borderRadius={1.5}
            sx={{
              background: '#E9F1F9',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              width: 28,
              height: 28,
            }}
          >
            <Typography gutterBottom variant="h6" fontSize="14px" fontWeight="bold" color="text.primary">
              {currentStep}
            </Typography>
          </Box>
        )}
      >
        <NftV2RichText markdown={content} />
      </StepLabel>
    </Box>
  )
}
