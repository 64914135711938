import RichText from '@/components/elements/rich-text'
import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { FC } from 'react'
import { v2theme } from 'src/theme/ThemeProvider'
import { NftPageQuery } from 'gatsby-graphql'

type Props = {
  content: NftPageQuery['strapiNft']['NftTermsOfService']
}

export const TermAndCondition: FC<Props> = ({ content }) => {
  const islgDown = useMediaQuery(v2theme.breakpoints.down('lg'))
  return (
    <Box
      py={islgDown ? 5 : 10}
      sx={{
        background: '#E9F1F9',
        boxShadow: `0 0 0 100vmax #E9F1F9`,
        clipPath: 'inset(0 -100vmax)',
      }}
    >
      <Typography
        variant="h2"
        fontWeight={700}
        gutterBottom
        fontSize={{ xs: 28, sm: 36 }}
        textAlign={{ xs: 'left', sm: 'center' }}
      >
        {content.title}
      </Typography>
      <Box sx={{ background: 'white', borderRadius: '16px', px: 3, border: '1px solid #CBD1E1' }}>
        <Box
          id="nft-tos"
          height={{ xs: '395px', sm: '395px', md: '395px', lg: '474px' }}
          sx={{
            maxHeight: '500px',
            overflowY: 'scroll',
            paddingRight: '24px',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              display: 'block',
              background: 'transparent',
              width: '8px',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(255, 255, 255, 0.5)',
              borderRadius: '4px',
            },
          }}
        >
          <RichText markdown={content.Content.data.Content} preserveNewlines />
        </Box>
      </Box>
    </Box>
  )
}
