import { Box, Typography } from '@mui/material'
import { NftPageQuery, Strapi__Media } from 'gatsby-graphql'
import React, { FC } from 'react'
import { aboutSectionTransformer } from '../../transforms/about-section-transformer'
import Image from '@/components/image'
import { css } from '@emotion/css'

type Props = {
  contents: NftPageQuery['strapiNft']['NftAbout']
}

export const AboutSectionMobile: FC<Props> = ({ contents }) => {
  const { title, background, icon, video } = aboutSectionTransformer(contents)
  return (
    <Box display="flex" flexDirection="column" sx={{ background: 'black' }}>
      <Box sx={{ height: '300px', position: 'relative' }}>
        <Box height="100%">
          <iframe
            src={video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className={css`
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100%;
              height: 100%;
              transform: translate(-50%, -50%);
              border: none;

              @media (min-aspect-ratio: 16/9) {
                height: 56.25vw;
              }
              @media (max-aspect-ratio: 16/9) {
                width: 177.78vh;
              }
            `}
          ></iframe>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${background.localFile.publicURL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: 'auto',
          height: '280px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image media={icon as Strapi__Media} style={{ objectFit: 'contain', height: '94px' }} />
          <Box width="300px">
            <Typography component="h4" align="center" fontSize="30px" fontWeight="bold" color="white">
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

// https://player.vimeo.com/video/911049729?h=5e14e3d690
