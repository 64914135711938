import { formatEther } from 'viem'

export type PriceRange = {
  range: string
  value: string
  hidePrice: boolean
  type: 'current' | 'upcoming' | 'past'
}

export const modifyPriceRange = (
  config: Omit<PriceRange, 'type'>[],
  supply: { supply: number; price: bigint },
): PriceRange[] => {
  return config.map((item) => {
    const [start, end] = item.range.split(' to ').map(Number)
    if (supply.supply > end) {
      return { ...item, type: 'past', hidePrice: true }
    }
    if (supply.supply >= start && supply.supply <= end) {
      return { ...item, value: `${Number(formatEther(supply.price)).toFixed(2)} ETH`, type: 'current' }
    }
    return { ...item, type: 'upcoming' }
  })
}
